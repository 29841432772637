//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SigninForm from '~/components/auth/SigninForm'

export default {
  auth: 'guest',
  components: {
    SigninForm
  },
  computed: {
    signupUrl () {
      return this.localePath('/sign-up')
    },
  }
}
